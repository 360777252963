import { useContext } from 'react';
import {
  Box,
  Checkbox,
  IconButton,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { LmmJobResponse } from '../shared/entity.js';
import { JobStatus } from '../shared/const.js';
import { GlobalContext } from '../context/GlobalContext.js';
import { useScreenDimensions } from '../shared/hook/useScreenDimensions.js';
import { MyIcon } from '../shared/component/Icons.js';
import TrashIcon from '../asset/icons/TrashIcon.js';
import { capitalizeEachWord, formatAsCurrencyAlt, getMonthYearDate } from '../utils/stringUtils.js';
import { stringToLatLng } from '../map/mapHelper.js';
import { StatusTag } from './StatusTag.js';

interface TableComponentProps {
  tableId: string;
  rows: LmmJobResponse[];
  setJobInEdit: (job: LmmJobResponse) => void;
  onDeleteJob: (job: LmmJobResponse) => void;
}

export const PlanTable = ({ tableId, rows, setJobInEdit, onDeleteJob }: TableComponentProps) => {
  const { width } = useScreenDimensions();

  return (
    <Box>
      <Droppable droppableId={tableId}>
        {(provided) => (
          <TableContainer ref={provided.innerRef} {...provided.droppableProps}>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr height="56px">
                  {/* <Th sx={styles.checkBox} width="40px">
                    <Checkbox borderColor="#979BA6" />
                  </Th> */}
                  <Th sx={styles.tableHeader} width={`${width * 0.15}px`}>
                    STREET NAME
                  </Th>
                  <Th sx={styles.tableHeader}>STARTING MILEPOST</Th>
                  <Th sx={styles.tableHeader}>ENDING MILEPOST</Th>
                  <Th sx={styles.tableHeader}>STATUS</Th>
                  <Th sx={styles.tableHeader} textAlign="right">
                    PLANNED DATE
                  </Th>
                  <Th sx={styles.tableHeader}>TREATMENT</Th>
                  <Th sx={styles.tableHeader} textAlign="right">
                    ESTIMATED COST
                  </Th>
                  <Th sx={styles.tableHeader}>OWNER</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody sx={{ ...styles.tableBody, ...(rows.length == 0 && { backgroundColor: 'transparent' }) }}>
                {rows?.length ? (
                  rows.map((job, index) => (
                    <Draggable key={job.id} draggableId={job.id} index={index}>
                      {(provided, snapshot) => (
                        <Tr
                          key={index}
                          sx={styles.tableRow}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {/* <Td sx={styles.checkBox} onClick={() => setJobInEdit(job)}>
                            <Checkbox borderColor="#979BA6" />
                          </Td> */}
                          <Td sx={styles.tableData} onClick={() => setJobInEdit(job)}>
                            {capitalizeEachWord(job.roadName)}
                          </Td>
                          <Td sx={styles.tableData} onClick={() => setJobInEdit(job)} title={job.startingMilepost}>
                            {stringToLatLng(job.startingMilepost) ? 'Post 1' : job.startingMilepost}
                          </Td>
                          <Td sx={styles.tableData} onClick={() => setJobInEdit(job)} title={job.endingMilepost}>
                            {stringToLatLng(job.endingMilepost) ? 'Post 2' : job.endingMilepost}
                          </Td>
                          <Td border="none" onClick={() => setJobInEdit(job)}>
                            <StatusTag status={job.status} />
                          </Td>
                          <Td sx={styles.tableData} textAlign="right" onClick={() => setJobInEdit(job)}>
                            {job.plannedDate?.length ? getMonthYearDate(job.plannedDate) : '-'}
                          </Td>
                          <Td sx={styles.tableData} onClick={() => setJobInEdit(job)}>
                            {job.treatmentName}
                          </Td>
                          <Td sx={styles.tableData} textAlign="right" onClick={() => setJobInEdit(job)}>
                            {formatAsCurrencyAlt(job.estimatedCost)}
                          </Td>
                          <Td sx={styles.tableData} onClick={() => setJobInEdit(job)}>
                            {job.ownerName}
                          </Td>
                          <Td>
                            <Menu>
                              <MenuButton
                                as={IconButton}
                                icon={
                                  <IconButton
                                    background="transparent"
                                    type="button"
                                    title="Actions"
                                    aria-label="Actions"
                                    icon={<MyIcon icon="more_vert" style={{ color: '#979BA6', fontSize: '25px' }} />}
                                    size="sm"
                                    _hover={{ background: 'transparent' }}
                                    _focus={{ background: 'transparent', boxShadow: 'none' }}
                                    _active={{ background: 'transparent' }}
                                  />
                                }
                                variant="ghost"
                                aria-label="More options"
                              />
                              <MenuList
                                width="120px"
                                background="#2C2C2C"
                                p={0}
                                borderRadius="12px"
                                border="1px #383839 solid"
                              >
                                <MenuItem
                                  height="48px"
                                  icon={<TrashIcon />}
                                  borderRadius="12px"
                                  onClick={() => onDeleteJob(job)}
                                  sx={{
                                    backgroundColor: '#2C2C2C',
                                    '&:hover': {
                                      backgroundColor: '#5a5a5b',
                                    },
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: '16px',
                                      color: '#E76262',
                                      lineHeight: '24px',
                                      fontWeight: '500',
                                    }}
                                  >
                                    Delete Job
                                  </span>
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Td>
                        </Tr>
                      )}
                    </Draggable>
                  ))
                ) : (
                  <></>
                )}

                {provided.placeholder}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Droppable>
      {/* {(!Array.isArray(rows) || rows.length == 0) && (
        <i style={{ fontSize: '12px', textAlign: 'center', marginLeft: '50%' }}>No record found</i>
      )} */}
    </Box>
  );
};

const styles = {
  tableHeader: {
    fontSize: '14px',
    lineHeight: '24px',
    color: 'var(--border-color-lighter)',
    fontWeight: '600',
    border: 'none',
  },
  tableData: {
    color: '#ffffff',
    fontSize: '16px',
    lineHeight: '24px',
    border: 'none',
  },
  checkBox: {
    border: 'none',
  },
  tableRow: {
    backgroundColor: '#464647',
    height: '56px',
    '&:hover': {
      backgroundColor: '#5a5a5b',
      cursor: 'pointer',
    },
  },
  tableBody: {
    backgroundColor: '#464647',
    borderRadius: '8px',
  },
};
