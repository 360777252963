import { IconButton, Spinner } from '@chakra-ui/react';
import { useContext } from 'react';
import type { LmmImagesFilter, LmmSection } from '../shared/entity.js';
import { MyIcon } from '../shared/component/Icons.js';
import { AssetType } from '../shared/const.js';
import { ShowRoadAssetsFilter } from '../interface.js';
import { GlobalContext } from '../context/GlobalContext.js';
import { MapFilter } from './MapFilter.js';

export function MapToolbar({
  lmmImagesFilter,
  setLmmImagesFilter,
  recenter,
  deselectLmmSection,
  showDeselectSection,
  loadingSection,
  toggleRoadAssetView,
  assetFilterisActive,
  onFilterMap,
  showFilter,
  action,
  setAction,
  showAssets,
}: {
  lmmImagesFilter: LmmImagesFilter;
  setLmmImagesFilter: (lmmImagesFilter: LmmImagesFilter) => void;
  recenter: (position?: google.maps.LatLng) => void;
  deselectLmmSection: () => void;
  showDeselectSection: boolean;
  loadingSection: boolean;
  toggleRoadAssetView: (type?: AssetType) => void;
  assetFilterisActive: boolean;
  onFilterMap: (pci: [number, number]) => void;
  showFilter: ShowRoadAssetsFilter;
  action: string;
  setAction: (action: string) => void;
  showAssets: {
    roadSigns: boolean;
    guardrails: boolean;
    sidewalks: boolean;
    inlets: boolean;
  };
}) {
  const { state } = useContext(GlobalContext);
  return (
    <div className="mapToolbar rightToolbar">
      {showDeselectSection && (
        <IconButton
          onClick={deselectLmmSection}
          className="deselectSection"
          title="Deselect sections"
          aria-label="Deselect sections"
          icon={
            loadingSection ? (
              <Spinner
                width="25px"
                height="25px"
                thickness="3px"
                speed="0.65s"
                emptyColor="gray.200"
                color="gray.500"
                size="xl"
              />
            ) : (
              <MyIcon icon="arrow_back" />
            )
          }
          size="sm"
          isDisabled={state.isCreatingJob}
        />
      )}
      <MapFilter
        lmmImagesFilter={lmmImagesFilter}
        setLmmImagesFilter={setLmmImagesFilter}
        toggleRoadAssetView={toggleRoadAssetView}
        filterisActive={assetFilterisActive}
        onFilterMap={onFilterMap}
        showFilter={showFilter}
        action={action}
        setAction={setAction}
        showAssets={showAssets}
        disabled={state.isCreatingJob}
      />
      <IconButton
        onClick={() => recenter()}
        className="fitBounds"
        title="Recenter Map"
        aria-label="Recenter Map"
        icon={<MyIcon icon="recenter" />}
        size="sm"
        isDisabled={state.isCreatingJob}
      />
    </div>
  );
}
