/* eslint-disable complexity */
import type { Geometry, Point } from 'geojson';
import type { LmmImage, LmmRoad, LmmRoadAsset, LmmSection, LmmSegment } from '../shared/entity.js';
import { AssetStatus, AssetType, PciCategory, pciCategories } from '../shared/const.js';
import { APP_COLORS, PCI_COLORS } from '../utils/constants.js';

const colors = Object.freeze({
  excellent: PCI_COLORS.excellent,
  good: PCI_COLORS.good,
  fair: PCI_COLORS.fair,
  poor: PCI_COLORS.poor,
});

export function getSegmentImages(lmmImages: readonly LmmImage[], lmmSection: LmmSection) {
  return lmmImages.filter((i: LmmImage) => isSegmentImage(i, lmmSection));
}

export function isSegmentImage(lmmImage: LmmImage, lmmSection: LmmSection) {
  return (
    (lmmImage.sectionId && lmmImage.sectionId === lmmSection.id) ||
    (lmmImage.roadId && lmmImage.roadId === lmmSection.roadId)
  );
}

export function getSegmentMarkers(
  markers: readonly google.maps.marker.AdvancedMarkerElement[],
  lmmSection: LmmSection
) {
  return markers.filter((m) => isSegmentMarker(m, lmmSection));
}

export function isSegmentMarker(marker: google.maps.marker.AdvancedMarkerElement, lmmSection: LmmSection) {
  return (
    (marker.dataset.roadId && marker.dataset.roadId === lmmSection.roadId) ||
    (marker.dataset.sectionId && marker.dataset.sectionId === lmmSection.id)
  );
}

export function getColorFromPci(pci: number) {
  if (pci) {
    const categoryKey = getCategoryKeyFromPci(pci);
    return getColorFromCategoryKey(categoryKey);
  }
  return '#C0C0C0';
}

export function getColorFromCategoryKey(categoryKey: PciCategory) {
  return colors[categoryKey];
}

export function getCategoryKeyFromPci(pci: number = 0) {
  const vals = Object.values(pciCategories);
  return vals.find((c) => pci <= c.max && pci >= c.min).key as PciCategory;
}

export function buildFeatures(sections: readonly LmmSection[] | LmmRoad[]) {
  return sections.map((section) => buildFeature(section));
}

export function buildFeature(section: LmmSection | LmmRoad): { type: 'Feature' } & google.maps.Data.FeatureOptions {
  const { id, geometry, ...properties } = section;
  return {
    type: 'Feature',
    id,
    properties: {
      section: properties,
    },
    geometry: geometry as unknown as google.maps.Data.Geometry,
  };
}

export function buildSection(feature: google.maps.Data.Feature): LmmSection | LmmRoad {
  const geometry = feature.getGeometry() as unknown as Geometry;
  return {
    id: feature.getId() as string,
    geometry,
    ...(feature.getProperty('section') as LmmSection | LmmRoad),
  };
}

export const manualPci = 80;

export const envPartnersId = '75842067-146f-4322-9938-45aeac596032';

export function buildManualStopSignImage() {
  return {
    id: 'efb7007a-4f70-449d-80f1-2074444c79c2',
    tenantId: envPartnersId,
    pci: manualPci,
    assetType: AssetType.stopSign,
    createdAt: new Date('2024-02-29T16:44:47.768Z'),
    human: 0,
    path: 'dataset/demo_envpartners/stop-sign-good.png',
    tokens: 765,
    position: {
      type: 'Point',
      coordinates: [42.229513479, -70.97779074],
    },
  } satisfies LmmImage;
}

export function convertToGoogleMapsGeometry(geometryObj: Geometry): google.maps.Data.Geometry {
  switch (geometryObj.type) {
    case 'Point': {
      return new google.maps.Data.Point({ lat: geometryObj.coordinates[0], lng: geometryObj.coordinates[1] });
    }
    case 'LineString': {
      const lineStringCoords = geometryObj.coordinates.map((coord) => new google.maps.LatLng(coord[0], coord[1]));
      return new google.maps.Data.LineString(lineStringCoords);
    }
    case 'Polygon': {
      const polygonCoords = geometryObj.coordinates.map((ring) =>
        ring.map((coord) => new google.maps.LatLng(coord[0], coord[1]))
      );
      return new google.maps.Data.Polygon(polygonCoords);
    }
    case 'MultiLineString': {
      const multiLineStringCoords = geometryObj.coordinates.map((line) =>
        line.map((coord) => new google.maps.LatLng(coord[1], coord[0]))
      );
      return new google.maps.Data.MultiLineString(multiLineStringCoords);
    }
    // Dami - If needed add other specific cases . default should only run when, type is not provided.
    default: {
      const latLanCoords: google.maps.LatLng[] = [];
      (geometryObj as unknown as google.maps.Data.Geometry).forEachLatLng((e) => {
        latLanCoords.push(e);
      });
      return new google.maps.Data.LineString(latLanCoords);
    }
  }
}

export const extractCoordinates = (latLngArray: google.maps.LatLng[]) => {
  return latLngArray.map((latLng) => {
    const coord = {
      lat: latLng.lat(),
      lng: latLng.lng(),
    };
    return new google.maps.marker.AdvancedMarkerElement({
      position: coord,
    });
  });
};

export function addFeatureGroup(
  map: google.maps.Map,
  featureData: LmmRoadAsset[],
  geometryGroup: Geometry[],
  groupId: string,
  color: string[],
  setFeatureToDisplay: React.Dispatch<React.SetStateAction<{ [key: string]: google.maps.Polyline[][] }>>,
  onFeatureClick?: (asset: LmmRoadAsset) => void
) {
  //adjust this functionality when handling more that guardrails
  const newGroup = geometryGroup.map((geometryObj, index) => {
    const latLngLiteralArray = (geometryObj as unknown as Point).coordinates.map(
      (coord: any) => new google.maps.LatLng(coord[1], coord[0])
    );
    const polyline = new google.maps.Polyline({
      path: latLngLiteralArray,
      geodesic: true,
      // strokeColor: color,
      strokeOpacity: 1,
      // strokeWeight: 4,
      // id: groupId,
      icons: [
        {
          icon: {
            path: 'M 0,-1 0,1',
            strokeColor: featureData[index].status == AssetStatus.Good ? color[0] : APP_COLORS.red, // Color for the first segment
            strokeWeight: 5,
          },
          offset: '0',
          repeat: '20px',
        },
        {
          icon: {
            path: 'M 0,-1 0,1',
            strokeColor: featureData[index].status == AssetStatus.Good ? color[1] : '#fbe3e3', // Color for the second segment
            strokeWeight: 5,
          },
          offset: '10px',
          repeat: '20px',
        },
      ],
      map,
    });

    const clickablePolyline = new google.maps.Polyline({
      path: latLngLiteralArray,
      geodesic: true,
      strokeOpacity: 0, // Fully transparent
      strokeWeight: 20, // Larger clickable area
      clickable: true,
      map,
      zIndex: 1, // Below the visible polyline
    });

    clickablePolyline.addListener('click', () => {
      if (onFeatureClick) {
        onFeatureClick(featureData[index]);
      } else {
        console.log(`Polyline clicked: Group ID - ${groupId}, Index - ${index}`);
      }
    });
    // polyline.setMap(map);
    return [polyline, clickablePolyline];
  });

  setFeatureToDisplay((prevGroups) => ({
    ...prevGroups,
    [groupId]: newGroup,
  }));
}

export function removeFeatureGroup(
  groupId: string,
  setFeatureToDisplay: React.Dispatch<
    React.SetStateAction<{ [key: string]: google.maps.Polyline[] | google.maps.Polyline[][] }>
  >
) {
  setFeatureToDisplay((prevGroups) => {
    const groupToRemove = prevGroups[groupId];
    if (groupToRemove && groupToRemove.length && !Array.isArray(groupToRemove[0])) {
      (groupToRemove as google.maps.Polyline[]).forEach((polyline) => polyline.setMap(null));
    } else if (groupToRemove && groupToRemove.length && Array.isArray(groupToRemove[0])) {
      (groupToRemove as google.maps.Polyline[][]).forEach((polyline) => {
        polyline[0].setMap(null);
        polyline[1].setMap(null);
      });
    }
    const { [groupId]: _, ...remainingGroups } = prevGroups;
    return remainingGroups;
  });
}

export function generateBoundsFromPoints(points: LmmImage[], miles: number) {
  if (!Array.isArray(points) || points.length === 0) {
    return null;
  }

  // Initialize variables to hold the minimum and maximum latitude and longitude
  let minLat = points[0].position.coordinates[0];
  let maxLat = points[0].position.coordinates[0];
  let minLng = points[0].position.coordinates[1];
  let maxLng = points[0].position.coordinates[1];

  // Iterate through the points to find the minimum and maximum latitude and longitude
  for (const point of points) {
    minLat = Math.min(minLat, point.position.coordinates[0]);
    maxLat = Math.max(maxLat, point.position.coordinates[0]);
    minLng = Math.min(minLng, point.position.coordinates[1]);
    maxLng = Math.max(maxLng, point.position.coordinates[1]);
  }

  // Convert miles to degrees
  const milesToDegreesLat = miles / 69; // 1 degree of latitude is approximately 69 miles
  const milesToDegreesLng = miles / (69 * Math.cos((((minLat + maxLat) / 2) * Math.PI) / 180)); // Adjust for longitude

  return {
    south: minLat - milesToDegreesLat,
    west: minLng - milesToDegreesLng,
    north: maxLat + milesToDegreesLat,
    east: maxLng + milesToDegreesLng,
  };
}

export function expandBoundsByDistance(bounds: google.maps.LatLngBounds, distance: number): google.maps.LatLngBounds {
  const { computeOffset } = google.maps.geometry.spherical;

  const northEast = bounds.getNorthEast();
  const southWest = bounds.getSouthWest();

  const expandedNorthEast = computeOffset(northEast, distance, 45); // 45° = NE
  const expandedSouthWest = computeOffset(southWest, distance, 225); // 225° = SW

  const expandedBounds = new google.maps.LatLngBounds();
  expandedBounds.extend(expandedNorthEast);
  expandedBounds.extend(expandedSouthWest);

  return expandedBounds;
}

// Function to find the closest point on the polyline to a given position
export function getClosestPointOnPath(
  position: google.maps.LatLng,
  polylinePath: google.maps.LatLng[]
): google.maps.LatLng {
  let closestPoint = polylinePath[0];
  let minDistance = google.maps.geometry.spherical.computeDistanceBetween(
    new google.maps.LatLng(position),
    new google.maps.LatLng(closestPoint)
  );

  // Iterate through each segment of the polyline
  for (let i = 0; i < polylinePath.length - 1; i++) {
    const segmentStart = polylinePath[i];
    const segmentEnd = polylinePath[i + 1];

    // Find the closest point on the segment
    const projectedPoint = google.maps.geometry.spherical.interpolate(
      new google.maps.LatLng(segmentStart),
      new google.maps.LatLng(segmentEnd),
      getFractionAlongSegment(position, segmentStart, segmentEnd)
    );

    // Calculate the distance to the projected point
    const distance = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(position),
      projectedPoint
    );

    // Update the closest point if the distance is smaller
    if (distance < minDistance) {
      minDistance = distance;
      closestPoint = projectedPoint;
    }
  }

  return closestPoint;
}

// Function to get the fraction along the segment for projection
const getFractionAlongSegment = (
  position: google.maps.LatLng,
  start: google.maps.LatLng,
  end: google.maps.LatLng
): number => {
  const totalDistance = google.maps.geometry.spherical.computeDistanceBetween(
    new google.maps.LatLng(start),
    new google.maps.LatLng(end)
  );
  const startToPositionDistance = google.maps.geometry.spherical.computeDistanceBetween(
    new google.maps.LatLng(start),
    new google.maps.LatLng(position)
  );
  return Math.min(Math.max(startToPositionDistance / totalDistance, 0), 1);
};

// export function isWithinDistanceOfTwoPoint(
//   point: google.maps.LatLng,
//   start: google.maps.LatLng,
//   end: google.maps.LatLng,
//   polylinePath: google.maps.LatLng[]
// ): boolean {
//   // Helper function to calculate the distance between two LatLng points

//   // Get the distance from start to end
//   const totalDistance = calculateDistance(start, end);

//   // Get the distance from start to the point
//   const distanceToPointFromStart = calculateDistance(start, point);

//   // Get the distance from the point to the end
//   const distanceToPointToEnd = calculateDistance(point, end);

//   // Check if the sum of the two distances (start -> point -> end) is close to the total distance
//   const tolerance = 1e-2; // Small tolerance for floating point accuracy
//   return Math.abs(distanceToPointFromStart + distanceToPointToEnd - totalDistance) < tolerance;
// }
// Helper function to calculate the distance between two LatLng points
function calculateDistance(pointA: google.maps.LatLng, pointB: google.maps.LatLng): number {
  return google.maps.geometry.spherical.computeDistanceBetween(pointA, pointB);
}

export function isBetweenTwoPointsAlongAPath(
  point: google.maps.LatLng,
  start: google.maps.LatLng,
  end: google.maps.LatLng,
  polylinePath: google.maps.LatLng[] = []
): boolean {
  let bound1IsFound = null;
  let bound2IsFound = null;
  for (let i = 0; i < polylinePath.length - 1; i++) {
    const currentPoint = polylinePath[i];
    const nextPoint = polylinePath[i + 1];
    const isStartBetween = isLatLngBetween(start, currentPoint, nextPoint);
    const isEndBetween = isLatLngBetween(end, currentPoint, nextPoint);
    const PointIsInSegment = isLatLngBetween(point, currentPoint, nextPoint);
    if (isStartBetween && !bound1IsFound) {
      bound1IsFound = 'start';
    } else if (isStartBetween && bound1IsFound == 'end') {
      bound2IsFound = 'start';
    }
    if (isEndBetween && !bound1IsFound) {
      bound1IsFound = 'end';
    } else if (isEndBetween && bound1IsFound == 'start') {
      bound2IsFound = 'end';
    }

    if (PointIsInSegment) {
      if (
        isStartBetween &&
        bound1IsFound == 'start' &&
        calculateDistance(currentPoint, point) >= calculateDistance(currentPoint, start)
      ) {
        return true;
      } else if (isStartBetween && bound1IsFound == 'start') {
        return false;
      } else if (
        isStartBetween &&
        bound2IsFound == 'start' &&
        calculateDistance(currentPoint, point) <= calculateDistance(currentPoint, start)
      ) {
        return true;
      } else if (isStartBetween && bound2IsFound == 'start') {
        return false;
      }
      if (
        isEndBetween &&
        bound2IsFound == 'end' &&
        calculateDistance(currentPoint, point) <= calculateDistance(currentPoint, end)
      ) {
        return true;
      } else if (isEndBetween && bound2IsFound == 'end') {
        return false;
      } else if (
        isEndBetween &&
        bound1IsFound == 'end' &&
        calculateDistance(currentPoint, point) >= calculateDistance(currentPoint, end)
      ) {
        return true;
      } else if (isEndBetween && bound1IsFound == 'end') {
        return false;
      }
      if (bound1IsFound && !bound2IsFound) {
        return true;
      }
      if ((bound1IsFound && bound2IsFound) || (!bound1IsFound && !bound2IsFound)) {
        return false;
      }
    }
  }
  return false;
}

function isLatLngBetween(point: google.maps.LatLng, start: google.maps.LatLng, end: google.maps.LatLng): boolean {
  if (point.equals(start) || point.equals(end)) {
    return true;
  }

  // Calculate distances
  const startToEndDistance = calculateDistance(start, end);
  const startToPointDistance = calculateDistance(start, point);
  const pointToEndDistance = calculateDistance(point, end);

  // Check if the point is collinear and within the bounds of start and end
  const tolerance = 1e-8; // Small tolerance for floating-point precision
  return Math.abs(startToPointDistance + pointToEndDistance - startToEndDistance) < tolerance;
}

// function calculateDistance(pointA: google.maps.LatLng, pointB: google.maps.LatLng) {
//   return google.maps.geometry.spherical.computeDistanceBetween(pointA, pointB);
// }

export function stringToLatLng(coordinate: string): google.maps.LatLng | null {
  const regex = /^\(\s*(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)\s*\)$/;
  const match = coordinate.match(regex);
  if (!match) return null;

  // Parse latitude and longitude
  const latitude = parseFloat(match[1]);
  const longitude = parseFloat(match[3]);

  if (latitude < -90 || latitude > 90 || longitude < -180 || longitude > 180) {
    return null;
  }

  return new google.maps.LatLng(latitude, longitude);
}
